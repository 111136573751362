import styled, { keyframes } from "styled-components";

const Shine = keyframes`
  100% {
    left: 200%;
  }
`
const Skeleton = styled.div`
    width: ${props => props.width ? props.width : 'auto'};
    height: ${props => props.height ? props.height : 'auto'};
    border-radius: ${props => props.radius ? props.radius : '12px'};
    margin: ${props => props.margin ? props.margin : '0 auto'};
    padding: ${props => props.padding ? props.padding : '8px'};
    margin-top: ${props => props.marginTop ? props.marginTop : '0'};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};
    background-color: #333E6E;
    overflow: hidden;
    position: relative;
    opacity: 0.5;
    &:before {
        // linear gradient with easing
        background-image: linear-gradient(
          to right,
          hsla(0, 0%, 100%, 0) 0%,
          hsla(0, 0%, 100%, 0.013) 4.05%,
          hsla(0, 0%, 100%, 0.049) 7.75%,
          hsla(0, 0%, 100%, 0.104) 11.25%,
          hsla(0, 0%, 100%, 0.175) 14.5%,
          hsla(0, 0%, 100%, 0.259) 17.65%,
          hsla(0, 0%, 100%, 0.352) 20.6%,
          hsla(0, 0%, 100%, 0.45) 23.55%,
          hsla(0, 0%, 100%, 0.55) 26.45%,
          hsla(0, 0%, 100%, 0.648) 29.4%,
          hsla(0, 0%, 100%, 0.741) 32.35%,
          hsla(0, 0%, 100%, 0.825) 35.5%,
          hsla(0, 0%, 100%, 0.896) 38.75%,
          hsla(0, 0%, 100%, 0.951) 42.25%,
          hsla(0, 0%, 100%, 0.987) 45.95%,
          hsla(0, 0%, 100%, 1) 50%,
          hsla(0, 0%, 100%, 0.987) 54.05%,
          hsla(0, 0%, 100%, 0.951) 57.75%,
          hsla(0, 0%, 100%, 0.896) 61.25%,
          hsla(0, 0%, 100%, 0.825) 64.5%,
          hsla(0, 0%, 100%, 0.741) 67.65%,
          hsla(0, 0%, 100%, 0.648) 70.6%,
          hsla(0, 0%, 100%, 0.55) 73.55%,
          hsla(0, 0%, 100%, 0.45) 76.45%,
          hsla(0, 0%, 100%, 0.352) 79.4%,
          hsla(0, 0%, 100%, 0.259) 82.35%,
          hsla(0, 0%, 100%, 0.175) 85.5%,
          hsla(0, 0%, 100%, 0.104) 88.75%,
          hsla(0, 0%, 100%, 0.049) 92.25%,
          hsla(0, 0%, 100%, 0.013) 95.95%,
          hsl(0, 0%, 100%, 0) 100%
        );
        mix-blend-mode: overlay;
        position: absolute;
        content:'';
        top: 0;
        left: -200%;
        height: 100%;
        width: 50%;
        transform: skewX(-20deg) scaleX(2);
        z-index: 0;
        animation-name: ${Shine};
        animation-duration: 1.25s;
        animation-iteration-count: infinite;
    }
`

export default Skeleton;