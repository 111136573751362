import find from "lodash.find";
import countries from "./countries.json";

const getCountryInfo = (data) => {
  let votes = [];
  for (const [key, value] of Object.entries(data)) {
    const country_item = find(countries, (c) => c.name === key);
    votes.push({ ...country_item, voteCount: value });
  }
  votes.sort((a, b) => b.voteCount - a.voteCount );
  return votes
}

export default getCountryInfo;