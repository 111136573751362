import React, { 
  useCallback,
  useEffect
} from "react";
import { 
  motion, 
  useAnimation 
} from "framer-motion";
import styled from "styled-components";
import queryString from "query-string";


const VerificationBar = ({ params }) => {

  console.log(params);

  // show a success notification based on contents of ?status= param. ("success", "failed", "expired")  
  const [verificationStatusShown, setVerificationStatusShown] = React.useState(false);
  const [verificationMessage, setVerificationMessage] = React.useState("Your vote has been confirmed");
  const [VerificationIcon, setVerificationIcon] = React.useState(Check);
  const animation = useAnimation()

  const showVerificationStatus = useCallback(
    async (msg, icon) => {
      setVerificationMessage(msg);
      setVerificationIcon(icon);
      setVerificationStatusShown(true);
      await animation.start({ y: 0, opacity: 1, transition: { opacity: { duration: 0 } } })
      animation.start({ y: -48, opacity: 0, transition: { delay: 8, opacity: { delay: 9 } } })
    }
  );

  useEffect(() => {
    const searchStr = params;
    const parsed = queryString.parse(searchStr, {arrayFormat: 'comma'});  
    const verificationStatus = parsed["status"];
    if(!verificationStatusShown) {
      switch (verificationStatus) {
        case "success":
          showVerificationStatus("Your vote has been confirmed", Check)
          break;
        case "failed":
          showVerificationStatus("Email verification failed. Please try again.", Exclamation)
          break;
        case "expired":
          showVerificationStatus("Verification link has expired. Please vote again.", Exclamation)
          break;
        default: 
          break;
      }
    }
  }, [params, verificationStatusShown, showVerificationStatus]);

  return (
    <SuccessBar
      initial={{y:-48}}
      animate={animation}
      transition={{ type: 'spring', bounce: 0, duration: 0.5, delay: 1.25 }}
    >
      <VerificationIcon/><span>{verificationMessage}</span>
    </SuccessBar>
  )
}

const Check = styled.div`
  width: 24px;
  height: 24px;
  background: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.69531 4.89156L4.82575 8.02199L9.9127 0.978516' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center center, ${props => props.theme.color.accentGreen};
  border-radius: 12px;
  display: flex;
  place-items: center;
`
const Exclamation = styled.div`
  width: 20px;
  height: 24px;
  border-radius: 3px;
  display: flex;
  place-items: center;
  background-color: ${props => props.theme.color.accentRed};
  &:after {
    content: "!";
    font-weight: 800;
    color: ${props => props.theme.color.uiCardEdge};
    width: 100%;
    height: 100%;
    text-align: center;
  }
`
const SuccessBar = styled(motion.div)`
  width: 100%;
  height: 48px;
  font-size: 1rem;
  color: #fff;
  background-color: ${props => props.theme.color.uiCardEdge};
  padding: 12px;
  text-align: center;
  font-weight: 600;
  margin: 0 auto;
  position: fixed;
  opacity: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  & span {
    margin-top: -2px;
  }
`

export default VerificationBar