import React, { useEffect, useRef } from "react";
import createGlobe from "cobe";
import getCountryInfo from "./getCountryInfo";
import { transform } from "framer-motion";
import styled from "styled-components";

export default function Earth({ data }) {

  const canvasRef = useRef();
  const locationRef = useRef([]);

  if (data) {

    // enrich data with coordinates and sort by vote count
    const votes = getCountryInfo(data);

    // map the vote tallies into a usable size range for the globe
    // - marker sizes will be between 0.0001 - 0.2 with the most voted country sized at 0.2
    const highestVoteCount = votes[0].voteCount;
    const transformer = transform([0,highestVoteCount],[0.0001,0.2]);

    // pass the country info to the globe
    // - we are only rendering the first 64 markers because the COBE library 
    // - doesn't support more than 64 markers for performance
    locationRef.current = votes.slice(0, 63).map((country) => ({
      location: [country.center_lat, country.center_lng],
      size: transformer(country.voteCount)
    }))
  }

  useEffect(() => {
    let phi = 0;
    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: 900 * 2,
      height: 900 * 2,
      phi: 0,
      theta: 0.03,
      dark: 1,
      diffuse: 1.25,
      mapSamples: 15000,
      mapBrightness: 12,
      mapBaseBrightness: 0.39,
      baseColor: [0.012,0.055,0.102],
      markerColor: [0.416,0.655,0.973],
      glowColor: [0.035,0.133,0.243],
      markers: [],
      offset: [-90, 0],
      context: { antialias: false },
      onRender: (state) => {
        // Called on every animation frame.
        // - `state` will be an empty object, return updated params.

        // rotate globe
        state.phi = phi;
        phi += 0.006;

        // add country marker data when it becomes available
        state.markers = locationRef.current;
      }
    });

    return () => {
      globe.destroy();
    };

  }, []);

  return <GlobeCanvas ref={canvasRef} />

}


const GlobeCanvas = styled.canvas`
  width: 900px;
  height: 900px;
  aspect-ratio: 1;
  position: fixed;
  ${props => props.theme.device.laptop} {
    position: fixed;
    width: 900px;
    height: 900px;
    top: 0;
    z-index: -1;
  }
`