import React from "react";
import Layout from "../components/shared/layout";
import Seo from "../components/shared/seo";
import Footer from "../components/sections/footer";
import Grid from "../components/shared/grid";
import Skeleton from "../components/shared/loading-skeleton";
import { H3, H5 } from "../components/shared/headings";
import styled from "styled-components";
import VoteForm from "../components/sections/leaderboard/vote-form";
import VoteTally from "../components/sections/leaderboard/vote-tally"
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import ogImage from "../images/og-image-leaderboard.png";
import Navbar from "../components/navbar/index";
import Earth from "../components/sections/leaderboard/earth";
import VerificationBar from "../components/sections/leaderboard/status";
import { VOTE_API_ENDPOINT } from "../components/sections/leaderboard/constants";

function LeaderboardPage({ location }) {
  const requestUrl = `${VOTE_API_ENDPOINT}/list`;
  const { 
    isLoading, 
    error, 
    data, 
    // isFetching 
  } = useQuery(["voteData"], () =>
    axios
      .get(requestUrl, { headers: {"Access-Control-Allow-Origin": "*"}})
      .then((res) => res.data)
  );

  if (error) return "An error has occurred: " + error.message;

  return (
    <Layout>
      <Seo
        title={"Country Leaderboard | Copilot"}
        ogTitle={"Copilot – Vote for where we go next"}
        description={"Cast your vote and get your country to the top of the leaderboard."}
        image={ogImage}
      />  
      <Navbar/>
        <VerificationBar params={location.search} />
        <Grid>
          <EarthContainer>
            <Earth data={data}/>
          </EarthContainer>
          <Leaderboard>
            <Title>Which country should Copilot launch in next?</Title>
            <VoteForm />
            <H5 style={{paddingTop: 40}}>Votes</H5>
            {isLoading ? <VoteTallySkeleton/> : <VoteTally data={data}/>}
          </Leaderboard>
        </Grid>
      <Footer isLeaderboardPage />
    </Layout>
  );
}

const VoteTallySkeleton = () => {
  return (
    <div style={{width: "100%", marginTop: 40, marginBottom: 72}}>
      <SkeletonRow>
        <Skeleton radius="2px" width="38px" height="22px" />
        <Skeleton width="180px" margin="0" marginTop="2px" height="18px" />
      </SkeletonRow>
      <SkeletonRow>
        <Skeleton radius="2px" width="38px" height="22px" />
        <Skeleton width="210px" margin="0" marginTop="2px" height="18px" />
      </SkeletonRow>
      <SkeletonRow>
        <Skeleton radius="2px" width="38px" height="22px" marginBottom="5px" />
        <Skeleton width="140px" margin="0" marginTop="2px" height="18px" />
      </SkeletonRow>
      <SkeletonRow style={{ borderTop: "1px solid rgba(255,255,255,0.08)" }}>
        <Skeleton radius="2px" width="38px" marginTop="24px" height="22px" />
        <Skeleton width="140px" margin="0" marginTop="26px" height="18px" />
      </SkeletonRow>
    </div>
  )
}
const SkeletonRow = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  gap: 12px;
  margin: 16px 0;
`
const EarthContainer = styled.div`
  grid-column: span 12;
  // position: relative;
  // margin: 0 -10px;
  // ${props => props.theme.device.tablet} {
  @media screen and (min-width: 600px) {
    position: fixed;
    margin: inherit;
  }
  ${props => props.theme.device.laptopLg} {
    // grid-column: col-start / span 7;
  }
`
const Leaderboard = styled.div`
  grid-column: span 12;
  z-index: 2;
  margin-top: 64px;
  margin-right: -10px;
  padding-right: 10px;
  position: relative;
  background-color: rgba(1,9,18,0.3);
  @media screen and (min-width: 600px) {
    // grid-column: col-start 2 / span 10;
    grid-column: col-start 6 / span 8;
    margin-top: 80px;
    background-color: #010912;
  }
  ${props => props.theme.device.tablet} {
    margin-right: -40px;
    padding-right: 40px;
  }
  ${props => props.theme.device.laptop} {
    grid-column: col-start 8 / span 5;
    margin-top: 120px;
  }
  ${props => props.theme.device.laptopLg} {
    grid-column: col-start 8 / span 4;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 90px;
    top: 0;
    left: -90px;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(
        90deg,
        hsl(212deg 89% 4% / 0%) 0%,
        hsl(212deg 89% 4% / 13%) 15%,
        hsl(212deg 89% 4% / 20%) 22%,
        hsl(212deg 89% 4% / 28%) 28%,
        hsl(212deg 89% 4% / 33%) 33%,
        hsl(212deg 89% 4% / 37%) 37%,
        hsl(212deg 89% 4% / 42%) 42%,
        hsl(212deg 89% 4% / 46%) 46%,
        hsl(212deg 89% 4% / 50%) 50%,
        hsl(212deg 89% 4% / 54%) 54%,
        hsl(212deg 89% 4% / 58%) 58%,
        hsl(212deg 89% 4% / 63%) 63%,
        hsl(212deg 89% 4% / 67%) 67%,
        hsl(212deg 89% 4% / 72%) 72%,
        hsl(212deg 89% 4% / 78%) 78%,
        hsl(212deg 89% 4% / 85%) 85%,
        hsl(212deg 89% 4% / 100%) 100%
      );

  }
`
const Title = styled(H3)`
  background: linear-gradient(90deg, #8EBDF9 3.07%, #D2DAE4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  font-weight: 600;
  font-size: ${props => props.theme.fontSize.medium2};
  line-height: ${props => props.theme.lineHeight.medium2};

  ${({ theme }) => theme.device.tablet} {
    font-weight: 800;
    font-size: ${props => props.theme.fontSize.large};
    line-height: ${props => props.theme.lineHeight.large};
  }
  ${({ theme }) => theme.device.laptop} {
    font-size: ${props => props.theme.fontSize.xlarge};
    line-height: ${props => props.theme.lineHeight.xlarge};
    margin-bottom: 0px;
  }
`

export default LeaderboardPage
