import React, { useState } from "react";
import { VisuallyHidden } from "@reach/visually-hidden";
import { H5 } from "../../shared/headings";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import countries from "./countries.json";
import { motion } from "framer-motion";
import axios from "axios";
import { VOTE_API_ENDPOINT } from "./constants";
import Spinner from "../../shared/Spinner";

const VoteForm = () => {

  // ui state
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [duplicateVote, setDuplicateVote] = useState(false);

  // controlled form inputs
  const [countryValue, setCountryValue] = useState("");
  const handleCountryChange = (e) => {
    setCountryValue(e.target.value);
  };
  const [emailValue, setEmailValue] = useState(undefined);
  const handleEmailChange = (e) => {
    setEmailValue(e.target.value);
  };
  const [optInValue, setOptInValue] = useState(false);
  const handleOptInChange = () => {
    setOptInValue(!optInValue);
  }

  // form validation
  const [emailValid, setEmailValid] = useState(true);
  const [countryValid, setCountryValid] = useState(true);
  const countryTipText = "Choose a country";
  const emailTipText = "Enter your email address";

  const validateOnSubmit = () => {
    let isValid = true;
    if (!countryValue) {
      setCountryValid(false);
      isValid = false;
    }
    if (!emailValue) {
      setEmailValid(false);
      isValid = false;
    }
    return isValid;
  }

  // on form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitError(false);
    const formIsValid = validateOnSubmit();
    // send form data if form fields are valid
    if(formIsValid) {
      setSubmitting(true);
      axios
        .post(VOTE_API_ENDPOINT, 
          { email: emailValue, country: countryValue, email_opted_in: optInValue },
          { headers: { "Access-Control-Allow-Origin": "*" }})
        // handle API response
        .then((response) => {
          setSubmitting(false);
          if (response.data.status === 'success') {
            setSubmitted(true);
          } else if (response.data.error) {
            setSubmitError(true);
          } else if (response.data.status === 'duplicate_email') {
            setDuplicateVote(true);
            setSubmitted(true);
          }
        })
        // catch any other errors
        .catch((error) => {
          setSubmitError(true);
        });

    }
  }

  const renderSubmittedMessage = () => (
    <FormSubmitted>
    {duplicateVote ? (
      <>
        <H5>You've already voted!</H5>
        <p>To keep things fair we're only counting one vote per person</p>
      </>
    ) : (
      <>
        <H5>Check your email</H5>
        <p>Click the link in the email we sent you to confirm your vote</p>
      </>
    )}
    </FormSubmitted>
  )
  const renderFormActions = () => (
    <>
      <FormAction>
        <ChBox>
          <input 
            type="checkbox"
            value="emailed_opted_in"
            id="email_opted_in"
            checked={optInValue}
            onChange={handleOptInChange}
          />
          <label htmlFor="email_opted_in">Notify me via email</label>
        </ChBox>
        <SubmitButton
          type="submit" 
          whileHover={{ scale: 1.05 }} 
          onClick={handleSubmit} 
        >
          {submitting && <Spinner />}
          Submit
        </SubmitButton>
      </FormAction>
      <Notice>By clicking Submit you are agreeing to Copilot&rsquo;s <Link to="/terms-of-service">terms of service</Link> and <Link to="/privacy-policy">privacy statement</Link>.</Notice>
    </>
  )

  return (
    <VoterForm>
      <H5>Cast your vote</H5>
      <Inputs>
        <FormElement>
        <VisuallyHidden id="country_label">Choose a country</VisuallyHidden>
          <select
            aria-labelledby="country_label" 
            name="country" 
            id="country" 
            value={countryValue} 
            onFocus={() => setCountryValid(true)} 
            onChange={handleCountryChange}
          >
            <option key="unset" value="">Country</option>
            {countries.map((country) => <option key={country.id} value={country.name}>{country.name}</option>)}
          </select>
          {!countryValid && <ErrorText>{countryTipText}</ErrorText>}
        </FormElement>
        <FormElement>
          <VisuallyHidden id="email_label">Enter your email address</VisuallyHidden>
          <input 
            type="text" 
            placeholder="Email address" 
            aria-labelledby="email_label" 
            name="email" 
            id="email"
            value={emailValue}
            onFocus={() => setEmailValid(true)} 
            onChange={handleEmailChange} 
          />
          {!emailValid && <ErrorText>{emailTipText}</ErrorText>}
        </FormElement>
      </Inputs>
      {submitError && <ErrorText>There was an error submitting your vote. Please try again.</ErrorText>}
      {!submitted ? (
        renderFormActions()
      ) : (
        renderSubmittedMessage()
      )}
    </VoterForm>
  )
}


const ChBox = styled.div`
  display: flex;
  align-items: center;
  grid-column: span 2;
  user-select: none;
  & label {
    font-size: 1rem;
    font-weight: 600;
    padding-left: 8px;
  }
  & input {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1.5px solid #31455E;
    border-radius: 0.2rem;
    background-color: ${props => props.theme.color.bg};
    margin: 0;
    &:checked {
      border: 1.5px solid ${props => props.theme.color.uiPillGround};
      border-radius: 0.2rem;
      background: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.69531 4.89156L4.82575 8.02199L9.9127 0.978516' stroke='%23071C37' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center center, ${props => props.theme.color.uiPillGround};
    }
  }
`
const FormElement = styled.div`
  & input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props => props.theme.color.uiPillGround};
  }
  & select {
    background: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.12207 2.06065L4.18482 6.37119C4.58347 6.93225 5.41653 6.93225 5.81518 6.37119L8.87793 2.06065C9.34838 1.39854 8.87498 0.481445 8.06275 0.481445H1.93725C1.12502 0.481445 0.651618 1.39854 1.12207 2.06065Z' fill='%236AA7F8'/%3E%3C/svg%3E%0A") no-repeat center right 1rem;
  }
  & input, & select {
    font-size: 1rem;
    line-height: 1.4;
    outline: none;
    border: none;
    padding: 10px 16px;
    border-radius: 0.35rem;
    color: ${props => props.theme.color.uiPillGround};
    border: 1px solid ${props => props.theme.color.uiCardEdge};
    background-color: ${props => props.theme.color.uiCardGround};
    font-family: 'Averta';
    font-weight: 600;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    &:focus {
      border: 1px solid ${props => props.theme.color.textLink};
    }
  }
`
const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${props => props.theme.device.tablet} {
    grid-template-columns: 1fr 1fr;    
  }
  ${props => props.theme.device.laptop} {   
    grid-template-columns: 1fr;
  }
  ${props => props.theme.device.laptopLg} {    
    grid-template-columns: 1fr 1fr;
  }
  gap: 16px;
`
const VoterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 24px 0;
  ${props => props.theme.device.tablet} {
    margin-top: 32px;
  }
  & h5 {
    grid-column: span 4;
  }
`
const FormAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const FormSubmitted = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 8px 0;
  color: ${props => props.theme.color.textSecondary};
  & h5 {
    color: ${props => props.theme.color.accentGreen};
  }
`
const ButtonStyle = css`
  border: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.color.textPrimary};
  color: ${(props) => props.theme.color.bg};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 1rem;
  font-family: 'Averta';
  font-weight: 600;
  scale: 1;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    box-shadow: 0 0 0 2pt ${(props) => props.theme.color.bg},
      0 0 0 5pt ${(props) => props.theme.color.textSecondary};
  }
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 5px;
  letter-spacing: -0.01em;
`;

const SubmitButton = styled(motion.button)`
  ${ButtonStyle}
`;

const Notice = styled.p`
  grid-column: span 4;
  font-size: ${props => props.theme.fontSize.small};
  color: ${props => props.theme.color.textSecondary};
  max-width: 320px;
  & a {
    text-decoration: none;
    color: ${props => props.theme.color.textLink};
  }
  & a:hover {
    color: ${props => props.theme.color.textPrimary};
  }
`
const ErrorText = styled(Notice)`
  max-width: none;
  color: ${props => props.theme.color.categoryOrange};
  margin-top: 2px;
`

export default VoteForm
