import React from "react";
import styled from "styled-components";
import getCountryInfo from "./getCountryInfo";

const VoteTally = ({ data }) => {
  const votes = getCountryInfo(data);
  return (
      <CountryList>
        {votes && votes.map((country, index) => {
          return (
            <Country key={index} name={country.name} voteCount={country.voteCount} flag={country.flag} />
          )
        })}
      </CountryList>
  )
}

const Country = ({ name, voteCount, flag }) => {
  return (
    <StyledCountry>
      <CountryLabel>
        <FlagImage><img alt={name} src={flag}/></FlagImage>
        {name}
      </CountryLabel>
      <VoteLabel>{voteCount}</VoteLabel>
    </StyledCountry>
  )
}

const CountryList = styled.ul`
  list-style: none;
  margin-bottom: 72px;
  margin-top: 20px;
  & li:nth-child(-n+3) {
      font-size: 1.25rem;
  }
  & li:nth-child(4) {
      margin-top: 12px;
      padding-top: 12px;
      border-top: 1px solid rgba(255,255,255,0.08);
  }
`
const StyledCountry = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  font-size: 1rem;
  font-family: 'Averta';
  font-weight: 600;
`
const FlagImage = styled.div`
  width: 40px;  
  position: relative;
  max-width 100%;
  float: left;
  & img {
    float: left;
  }
  &:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		box-shadow: inset 0 0 8px rgba(0,0,0,.6);
	} 
`
const CountryLabel = styled.span`
  display: flex;
  gap: 12px;
  align-items: center;
`
const VoteLabel = styled.span`
  font-weight: 800;
`

export default VoteTally